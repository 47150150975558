@tailwind base;
@tailwind components;
@tailwind utilities;

.two-cols{
    -webkit-columns: 40px 2;
            /* Chrome, Safari, Opera */
            -moz-columns: 60px 2;
            /* Firefox */
            columns: 60px 2;
}